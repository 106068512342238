var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('title',[_vm._v("Galactiq | Free")]),_vm._m(0),_c('section',{staticClass:"content-row content-row-gray content-row-color"},[_c('div',{staticClass:"container"},[_c('header',{staticClass:"content-header"}),_c('div',{staticClass:"column-row align-center-bottom"},[_c('div',{staticClass:"column-33"},[_c('div',{staticClass:"product-box"},[_vm._m(1),_c('div',{staticClass:"product-price"},[_vm._v(" $"+_vm._s(_vm.$root.$children[0].floatPriceToString(0))),_c('span',{staticClass:"term"},[_vm._v("/ month")])]),_vm._m(2),_vm._m(3)])])]),_vm._m(4),_vm._m(5)])]),_vm._m(6),_c('section',{staticClass:"content-row content-row-color"},[_c('div',{staticClass:"column-row align-center-bottom"},[_vm._m(7),_c('div',{staticClass:"column-90"},[_c('div',{staticClass:"container"},[_c('header',{staticClass:"content-header"},[_c('h2',[_vm._v(" Ready for the full experience? ")]),_c('p',[_vm._v(" Check out our Paid Minecraft Plans!"),_c('br'),_c('br'),_c('router-link',{staticClass:"button button-secondary",attrs:{"to":"/hosting/minecraft/java/standard"}},[_c('i',{staticClass:"fas fa-arrow-right icon-left"}),_vm._v("Standard Plans ")])],1)])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"content-row content-row-color content-gamebanner"},[_c('div',{staticClass:"container"},[_c('header',{staticClass:"content-header content-header-large content-header-uppercase"},[_c('h1',[_c('mark',[_vm._v("Minecraft Free")])]),_c('p',[_vm._v(" With new games, new updates, and new ways to play! Join one of the largest communities in gaming and start building today! ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"product-header"},[_c('img',{attrs:{"src":"https://cdn.galactiq.net/images/game-icons/minecraft/free/mcfree.png","alt":"","height":"64px"}}),_c('h4',{staticStyle:{"margin-top":"20px"}},[_vm._v(" Minecraft Free Plan ")]),_c('p',[_vm._v("Minecraft Free"),_c('br')]),_c('small',[_c('a',{attrs:{"href":"/network"}},[_vm._v("View Locations")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"product-features"},[_c('ul',[_c('li',[_c('strong',[_vm._v("2")]),_vm._v(" CPU cores")]),_c('li',[_c('strong',[_vm._v("3 GB")]),_vm._v(" RAM")]),_c('li',[_c('strong',[_vm._v("10 GB")]),_vm._v(" Disk space")]),_c('li',[_c('strong',[_vm._v("20")]),_vm._v(" Playerslots")])]),_c('ul')])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"product-order"},[_c('a',{staticClass:"button button-secondary",attrs:{"href":"https://console.galactiq.net/order?category=minecraft&subcategory=free"}},[_c('i',{staticClass:"fas fa-shopping-cart icon-left"}),_vm._v("Order Now ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('header',{staticClass:"content-header"},[_c('ul'),_c('h1',[_c('mark',[_vm._v("Server Platforms")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"column-row align-center-bottom"},[_c('div',{staticClass:"column-33"},[_c('div',{staticClass:"product-box"},[_c('div',{staticClass:"product-header"},[_c('img',{staticStyle:{"margin-top":"calc((64px - 23.73px) / 2)","margin-bottom":"calc((64px - 23.73px) / 2)"},attrs:{"src":"https://cdn.galactiq.net/images/server-jars/paper/paper-ico.webp","alt":"","width":"100px"}}),_c('h4',{staticStyle:{"margin-top":"20px"}},[_vm._v(" Paper ")])]),_c('div',{staticClass:"product-features"},[_c('ul')])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"content-row content-row-gray"},[_c('div',{staticClass:"container"},[_c('div',[_c('table',[_c('caption',[_vm._v(" Minecraft Addons ")]),_c('tbody',[_c('tr',[_c('td',[_vm._v("Addons are not available for free servers.")])])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"column-10"},[_c('img',{staticClass:"imgText searchMan",attrs:{"src":"https://cdn.galactiq.net/images/assets/search-man.svg"}})])
}]

export { render, staticRenderFns }